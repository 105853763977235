

import React from 'react'

class Selector extends React.Component {

    constructor(props) {
        super(props);
        this.filterFunc = this.props.filterFunc
        this.filters = this.props.filters
        this.black = "var(--black)"
        this.white = "var(--white)"
    }

    componentDidMount() {
        const [firstKey, firstValue] = Object.entries(this.filters)[0];
        document.getElementById(firstValue + '-slt').style.backgroundColor = this.black
        document.getElementById(firstValue + '-slt').style.color = this.white
    }

    baseFilterFunc(event, key) {
        for (const [longKey, key] of Object.entries(this.filters)) {
            let selector = key + '-slt'
            if (selector === event.target.id) {
                document.getElementById(event.target.id).style.backgroundColor = this.black
                document.getElementById(event.target.id).style.color = this.white
            } else {
                document.getElementById(selector).style.backgroundColor = this.white
                document.getElementById(selector).style.color = this.black
            }
        }
        this.filterFunc(event, key)
    }

    render() {
        return (
            <div className='filter-cnt'>
                {Object.entries(this.filters).map(([longKey, key]) => <div key={key + '-slt'} id={key + '-slt'} className='press-switch-ctn' onClick={(e) => this.baseFilterFunc(e, key)}>{longKey}</div>)}
            </div>
        );
    }
}

export default Selector;

