import React from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";

class Disclaimer extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <div>

          <Helmet>
            <title>Disclaimer - Financial Coffee</title>
            <meta name="description" content="This page provides the disclaimer of Financial Coffee." />
            <meta name="robots" content="noindex" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Disclaimer | Financial Coffee" />
            <meta property="og:description" content="This page provides the disclaimer of Financial Coffee." />
          </Helmet>

          <div className='center-readable c-read-h-margins'>
            <h1>Disclaimer</h1>
            <div><p>Guideline Center, 09.05.2024</p></div><br></br>
            <p>
              All data provided on Financial Coffee is provided for informational purposes only, and is not intended for trading or investing purposes.
            </p>
            <p>
              It is important for consumers to carefully review and analyze all available information before
              making any investment decisions. Financial Coffee makes no representations or warranties
              regarding the accuracy, completeness, or reliability of any information or data presented on its website.
              Furthermore, investors should be aware that past performance is not indicative of future results, and any
              investment carries a risk of loss. Financial Coffee is not liable for any damages, losses, or expenses
              incurred as a result of using its website or relying on any information or data provided therein.
            </p>
            <p>
              By accessing and using the website of Financial Coffee, consumers acknowledge and agree to its terms
              and conditions. It is recommended that consumers consult with a qualified financial advisor before making any
              investment decisions.
            </p>

          </div>

        </div>
      </HelmetProvider>
    );
  }
}

export default Disclaimer;