import React from 'react';
import PropTypes from 'prop-types';
import '../styles/ShowMore.css';

const ShowMore = ({ onClick, hasMore, loading }) => (
    <div className="show-more-container">
        {hasMore && (
            <button onClick={onClick} className="clickable-button" disabled={loading}>
                {loading ? <span className="loading-icon"></span> : 'Show More'}
            </button>
        )}
    </div>
);

ShowMore.propTypes = {
    onClick: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default ShowMore;
