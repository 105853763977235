import React from 'react'
import LEDBlock from './LEDBlock';
import '../styles/LEDCarousel.css';

class LEDCarousel extends React.Component {

  constructor(props) {
    super(props);
    this.speed = 15000
  }

  render() {
    return (
      <div className="led-ctn">
        <div className="wrapper">
          <section className='regular' style={{ "--speed": `${this.speed}ms` }}>
            {this.props.tickers.map((ticker, index) => (
              // Increase = New Number - Original Number
              // Increase ÷ Original Number × 100.
              <LEDBlock
                key={ticker.index + index}
                id={index}
                ticker={ticker.index}
                tickerValue={ticker.value}
                tickerValueChange={(ticker.value - ticker.closeValue).toFixed(3) + '(' + ((ticker.value - ticker.closeValue) / ticker.closeValue * 100).toFixed(2) + '%)'}
              />
            ))}
          </section>
          <section className='regular' style={{ "--speed": `${this.speed}ms` }}>
            {this.props.tickers.map((ticker, index) => (
              // Increase = New Number - Original Number
              // Increase ÷ Original Number × 100.
              <LEDBlock
                key={ticker.index + index}
                id={index}
                ticker={ticker.index}
                tickerValue={ticker.value}
                tickerValueChange={(ticker.value - ticker.closeValue).toFixed(3) + '(' + ((ticker.value - ticker.closeValue) / ticker.closeValue * 100).toFixed(2) + '%)'}
              />
            ))}
          </section>
          <section className='regular' style={{ "--speed": `${this.speed}ms` }}>
            {this.props.tickers.map((ticker, index) => (
              // Increase = New Number - Original Number
              // Increase ÷ Original Number × 100.
              <LEDBlock
                key={ticker.index + index}
                id={index}
                ticker={ticker.index}
                tickerValue={ticker.value}
                tickerValueChange={(ticker.value - ticker.closeValue).toFixed(3) + '(' + ((ticker.value - ticker.closeValue) / ticker.closeValue * 100).toFixed(2) + '%)'}
              />
            ))}
          </section>
          <section className='regular' style={{ "--speed": `${this.speed}ms` }}>
            {this.props.tickers.map((ticker, index) => (
              // Increase = New Number - Original Number
              // Increase ÷ Original Number × 100.
              <LEDBlock
                key={ticker.index + index}
                id={index}
                ticker={ticker.index}
                tickerValue={ticker.value}
                tickerValueChange={(ticker.value - ticker.closeValue).toFixed(3) + '(' + ((ticker.value - ticker.closeValue) / ticker.closeValue * 100).toFixed(2) + '%)'}
              />
            ))}
          </section>
        </div>
      </div>
    );
  }

}

export default LEDCarousel;