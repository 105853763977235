import React, { Component } from "react";
import '../styles/ArticleSnippetLarge.css';
import { getDateDayDifferenceString } from "../utils";
import axios from 'axios';

class ArticleSnippetLarge extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.id
    this.title = this.props.title;
    this.link = this.props.link;
    this.category = this.props.category;
    this.content = this.props.content;
    this.date = this.props.date
    this.thumbnail = this.props.thumbnail
    this.tickers = this.props.tickers ? this.props.tickers.split(',') : [];

    this.explicitThumbnails = {
      'European Central Bank': 'ecb.jpg',
      'Federal Reserve': 'fed.jpg',
      'Bank Of England': 'boe.jpg',
      'Bank Of Canada': 'boc.jpg',
      'Bank Of Japan': 'boj.jpg',
      'Swiss National Bank': 'snb.jpg',
      'Reserve Bank of Australia': 'rba.png',
    }
  }

  handleClick = () => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/v1/items/ping/' + this.props.id).catch(err => console.log(err))
  };

  handleAuxClick = (e) => {
    if (e.button === 1) {
      this.handleClick()
    }
  };

  imgSrc() {
    if (this.category in this.explicitThumbnails) {
      return this.explicitThumbnails[this.category]
    }
    if (this.props.thumbnail === undefined || this.props.thumbnail === null) {
      return "placeholder2.jpg"
    } else {
      return this.props.thumbnail
    }
  }

  render() {
    return (

      <div className="snip-container-flex-large">
        <img className="snip-img" src={this.imgSrc()} alt="" />
        <div className="snip-content">
          <div className="snip-sub">
            <p className="snip-tag">{this.props.category}</p>
            <p className="snip-subtag">{getDateDayDifferenceString(this.props.date)}</p>
          </div>
          <a
            className="snip-title snip-link minimal-anchor"
            href={this.content ? this.id : this.link}
            target={this.content ? "_self" : "_blank"}
            rel="noreferrer"
            onClick={this.handleClick}
            onAuxClick={this.handleAuxClick
            }>
            {this.props.title}
          </a>
          <div className="snip-sub snip-tickers">
            {this.tickers.map((ticker, index) => (
              <div className="snip-ticker-ctn" key={ticker}>{ticker}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleSnippetLarge;
