import React from 'react';
import '../styles/SearchBar.css';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sbValue: ''
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        // Initial fetch or logic to get suggestions should ideally be triggered from the parent component or passed via props.
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleChange(event) {
        const query = event.target.value;
        this.setState({ sbValue: query });
        this.props.onSearch(query);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    render() {
        const { sbValue } = this.state;
        const { searchSuggestions } = this.props;

        return (
            <div className="sb-ctn" ref={this.setWrapperRef}>
                <input
                    id="sb"
                    type="text"
                    placeholder="Looking for a company?"
                    className={searchSuggestions.length > 0 ? 'with-suggestions' : ''}
                    value={sbValue}
                    onChange={(e) => this.handleChange(e)}
                />
                {searchSuggestions.length > 0 && (
                    <div className="suggestions">
                        {searchSuggestions.map((suggestion, index) => (
                            suggestion.div(index)
                        ))}
                    
                    </div>
                )}
            </div>
        );
    }
}

export default SearchBar;


