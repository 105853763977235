import React, { Component } from "react";
import '../styles/ArticleSnippetLarge.css';
import { getDateDayDifferenceString } from "../utils";
import axios from 'axios';

class ArticleSnippet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPlaceholder: !props.title || !props.subtitle
        };
    }

    handleClick = () => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + '/v1/items/ping/' + this.props.id).catch(err => console.log(err))
    };

    handleAuxClick = (e) => {
        if (e.button === 1) {
            this.handleClick()
        }
    };

    getLinkStyle(link, title) {
        if (String(title).toLowerCase().includes("statement") ||
            String(title).toLowerCase().includes("announces") ||
            String(title).toLowerCase().includes('assessment') ||
            String(title).toLowerCase().includes('investigates') ||
            String(title).toLowerCase().includes('bankruptcy') ||
            String(title).toLowerCase().includes('charges') ||
            String(title).toLowerCase().includes('policy')) {
            return {}
        }

        return { display: 'none' };
    }

    getLinkText(link, title) {
        const linkLowerCase = String(link).toLowerCase();
        const titleLowerCase = String(title).toLowerCase();

        switch (true) {
            case titleLowerCase.includes("statement"):
                return 'Statement';
            case titleLowerCase.includes("announces"):
                return 'Announcement';
            case titleLowerCase.includes('assessment'):
            case titleLowerCase.includes('policy'):
                return 'Monetary Policy';
            case titleLowerCase.includes('monetary policy'):
                return 'Monetary Policy';
            case titleLowerCase.includes('investigates'):
                return 'Investigation'
            case titleLowerCase.includes('charges'):
                return 'Charges'
            case titleLowerCase.includes('bankruptcy'):
                return 'Bankruptcy'
            default:
                return '';
        }
    }

    render() {
        const {
            title,
            link,
            category,
            date,
            content,
            id,
        } = this.props;

        return (

            <div className="snip-container-simple ">
                <div className="snip-sub">
                    <p className="snip-tag">{category}</p>
                    <p className="snip-subtag">{getDateDayDifferenceString(date)}</p>
                </div>

                <a
                    className="snip-title snip-link minimal-anchor"
                    href={content ? id : link}
                    target={content ? "_self" : "_blank"}
                    rel="noreferrer"
                    onClick={this.handleClick}
                    onAuxClick={this.handleAuxClick}
                >
                    {title}</a>
                <div className="snip-sub snip-tickers">
                    <div className="snip-ticker-ctn" style={this.getLinkStyle(link, title)}>{this.getLinkText(link, title)}</div>
                </div>
            </div>

        );
    }
}

export default ArticleSnippet;
