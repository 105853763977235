import React from 'react'
import '../styles/LEDBlock.css';

class LEDBlock extends React.Component {

  constructor(props) {
    super(props);
    this.isPositiveChange = !this.props.tickerValueChange.includes('-')
    if (this.isPositiveChange) {
      this.changeSign = '+'
    } else {
      this.changeSign = ''
    }

  }

  render() {
    return (
      <div className="tickerCard">
        <p className='carouselTickerName'>{this.props.ticker}</p>
        <p className='carouselTickerValue'>{this.props.tickerValue}</p>
        <p className='carouselTickerValueChange' style={{ "--color": `${((this.isPositiveChange) ? 'green' : 'red')}` }}>{this.changeSign} {this.props.tickerValueChange}</p>
      </div>
    );
  }
}

export default LEDBlock;