import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { calculateOwnershipDelta, formatNumberToKString, parseFloatToUSString } from '../utils';
import ShowMore from './ShowMore'; // Import the ShowMore component



class InsiderTransactionsTable extends Component {


    constructor(props) {
        super(props);
        this.codeColorMap = {
            P: 'rgb(230, 244, 234)',
            S: 'rgb(252, 232, 230)',
            A: 'rgb(252, 246, 212)',
            O: 'rgb(252, 232, 230)',
            I: 'rgb(230, 244, 234)',
            M: 'rgb(230, 244, 234)',
            G: 'rgb(252, 246, 212)',
        };
        this.codeMap = {}
        this.state = {
            isMobile: window.matchMedia('(max-width: 768px)').matches,
            visibleItems: 21,
        };
        this.handleMediaQueryChange = this.handleMediaQueryChange.bind(this);
        this.showMoreItems = this.showMoreItems.bind(this);
    }

    handleMediaQueryChange(event) {
        this.setState({ isMobile: event.matches });
    }

    componentWillUnmount() {
        this.mediaQueryListener.removeEventListener('change', this.handleMediaQueryChange);
    }

    setShorterNames() {
        this.codeMap = {
            P: 'P',
            S: 'S',
            A: 'G',
            O: 'O',
            M: 'M',
            I: 'O',
            G: 'G',
        };
    }

    resetNames() {
        this.codeMap = {
            P: 'Buy',
            S: 'Sell',
            A: 'Grant',
            M: 'Exercise',
            O: 'Options Exercise Out-of-the-Money',
            I: 'Options Exercise In-the-Money',
            G: 'Gift',
        };
    }

    showMoreItems() {
        this.setState(prevState => ({
            visibleItems: prevState.visibleItems + 100,
        }));
    }

    componentDidMount() {
        this.mediaQueryListener = window.matchMedia('(max-width: 768px)');
        this.mediaQueryListener.addEventListener('change', this.handleMediaQueryChange);
    }
    
    render() {
        const { transactions, hasTransactions } = this.props;
        const { isMobile, visibleItems } = this.state;

        if (isMobile) {
            this.setShorterNames();
        } else {
            this.resetNames();
        }

        // Logic for displaying transactions per page
        // Determine the transactions to display
        const currentTransactions = transactions.slice(0, visibleItems);
        const hasMoreItems = visibleItems < transactions.length;

        return (
            <div>
                {hasTransactions ? (
                    <div className="table-container">
                        <table className="transactions-table">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Insider</th>
                                    <th>Company</th>
                                    <th>Shares</th>
                                    <th>Price</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTransactions.map((transaction, i) => (
                                    <tr key={`tr${i}`}>
                                        <td className="notwrap">
                                            <div className="label-base" style={{ backgroundColor: this.codeColorMap[transaction.tt] }}>
                                                {this.codeMap[transaction.tt] || 'Other'}
                                            </div>
                                        </td>
                                        <td className="notwrap">
                                            {transaction.rd}
                                            <p className='subtitle' style={{ fontSize: 'x-small' }}>Reported on {transaction.td}</p>
                                        </td>
                                        <td>
                                            {transaction.o}<br />
                                            <p className='subtitle' style={{ fontSize: 'x-small' }}>{transaction.ot}</p>
                                            {/* {transaction.o.startsWith('An executive') ? 'Executive' : transaction.o.startsWith('A non-executive') ? 'Non-Executive' : transaction.o.slice(0, 30)} */}
                                        </td>
                                        <td className="notwrap">
                                            <div className="label-base td-com">
                                                <a className='minimal-anchor' href={`/inspect/${transaction.t}`}>{transaction.t}</a>
                                            </div>
                                        </td>
                                        <td className="notwrap">
                                            <p className="amnt">{parseFloatToUSString(transaction.a, 0)}</p>
                                            <p className='subtitle' style={{ fontSize: 'x-small' }}>{transaction.c == 'CHF' ? "" : calculateOwnershipDelta(transaction.so, transaction.a, transaction.tt)
                                            }</p>
                                        </td>
                                        <td className="notwrap">
                                            <p>{transaction.c} {parseFloatToUSString(transaction.p)}</p>
                                        </td>
                                        <td className="notwrap">{transaction.c} {formatNumberToKString(parseFloatToUSString(parseFloat(transaction.p) * parseFloat(transaction.a)))}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <ShowMore onClick={this.showMoreItems} hasMore={hasMoreItems} />
                    </div>
                ) : (
                    <div>None to show.</div>
                )}
            </div>
        );
    }
}

InsiderTransactionsTable.propTypes = {
    transactions: PropTypes.arrayOf(PropTypes.shape({
        tt: PropTypes.string.isRequired,
        td: PropTypes.string.isRequired,
        o: PropTypes.string.isRequired,
        t: PropTypes.string.isRequired,
        a: PropTypes.string.isRequired,
        c: PropTypes.string.isRequired,
        p: PropTypes.string.isRequired
    })).isRequired,
    hasTransactions: PropTypes.bool.isRequired,
};

export default InsiderTransactionsTable;
